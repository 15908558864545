import * as React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { colours } from "../design/colour";

const StyledLink = styled(Link)`
  color: ${colours.black} !important;
  margin: 0.5rem;
  &:active,
  &:hover {
    color: ${colours.black} !important;
    text-decoration: underline ${colours.black};
  }
`;

interface PageLinkProps {
  to: string;
  children: React.ReactNode;
  style?: any;
}

export const PageLink: React.FC<PageLinkProps> = ({ to, children, style }) => (
  <StyledLink to={to} style={style}>
    {children}
  </StyledLink>
);
