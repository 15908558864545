/**
 * Dark colours sourced from putting original colours into 
 * https://pinetools.com/darken-color and using a 30% darker value
 */


export const colours = {
  white: "#ffffff",
  provenceBlue: "#658DC6",
  darkProvenceBlue: "#386098",
  babyBlue: "#B5C7D3",
  darkBabyBlue: "#6b8fa7",
  monumentGrey: "#84898C",
  darkMonumentGrey: "#5c6062",
  stuccoBrown: "#A58D7F",
  darkStuccoBrown: "#786154",
  peachQuartz: "#F5B895",
  darkPeachQuartz: "#ea6f28",
  greenQuartz: "#A5B895",
  lightCornHuskYellow: "#f8ead6",
  cornhuskYellow: "#F2D6AE",
  classicBlue: "#0F4C81",
  black: "#000000"
};
