import React from "react";
// import styled from '@emotion/styled'
import { css } from "@emotion/core";
// import { LunrSearch } from './LunrSearch'
import { PageLink } from "./PageLink";
import { colours } from "../design/colour";

const style = {
  container: css`
    background: ${colours.white};
    margin-bottom: 1.45rem;
    border-bottom: 3px solid ${colours.classicBlue} !important;
    text-rendering: optimizeLegibility;
  `,
  wrapper: css`
    display: grid;
    grid-template-columns: auto 10rem;
    grid-template-rows: auto;
    margin: 0 auto;
    max-width: 960px;
    padding: 1.45rem 1.0875rem;
  `,
  title: css`
    margin: 0;
    display: inline-block;
    font-size: 40px;
  `
};

interface HeaderProps {
  readonly title: string;
}

export const Header = ({ title }: HeaderProps) => (
  <div css={style.container}>
    <nav css={style.wrapper}>
      <h1 css={style.title}>
        <PageLink to="/">{title}</PageLink>
      </h1>
      <div style={{ display: "flex", flex: 1 }}>
        <PageLink
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center"
          }}
          to="/blog"
        >
          {"Blog"}
        </PageLink>
      </div>
      {/* <LunrSearch limit={10} /> */}
    </nav>
  </div>
);
